import React from "react";
import { Link } from "react-router-dom";
import { navLinks } from "../../utils";
import { getTickets, getPhotosOn } from "../../utils/selectors";
import { useSelector } from "../../hooks";
import { EventbriteButton } from "../";
import { StyledMenu } from "./menu.styled";

const Menu = ({ open, setMenuOpen }) => {
	const tickets = useSelector(getTickets);
	const photosOn = useSelector(getPhotosOn);

	return (
		<StyledMenu open={open}>
			<div className="flex flex-column  justify-between">
				{navLinks.map((link) =>
					!photosOn.data && link.title === "photos" ? (
						""
					) : link.title === "tickets" ? (
						tickets?.data?.eventId && (
							<EventbriteButton
								isLoading={tickets?.loading && !tickets?.data.eventId}
								ebEventId={tickets?.data && tickets?.data?.eventId}
								key={link.to}
							>
								Get {link.title}
							</EventbriteButton>
						)
					) : link.title === "volunteer" ? (
						<a
							rel="noopener noreferrer"
							target="_blank"
							className="link black dim f3 ttu pv3 fw7"
							href={link.to}
							key={link.to}
						>
							{link.title}
						</a>
					) : (
						<Link
							className="link black dim f3 ttu pv3 fw7"
							key={link.to}
							to={link.to}
							onClick={() => setMenuOpen(!open)}
						>
							{link.title}
						</Link>
					)
				)}
			</div>
		</StyledMenu>
	);
};

export default Menu;
