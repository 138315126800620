import { useContext } from "react";
import { SiteContext } from "../utils";

const useSelector = (selector) => {
	const [state, loading] = useContext(SiteContext);

	return {
		loading,
		data: selector(state),
	};
};

export default useSelector;
