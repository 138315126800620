import React from "react";
import { getHomeImage } from "../../utils/selectors";
import { useSelector } from "../../hooks";
import { Styled, isEmpty } from "../../utils";
import { EmptyState } from "../../components";
const Home = () => {
	const { data } = useSelector(getHomeImage);

	return !data || isEmpty(data) ? (
		<EmptyState />
	) : (
		<Styled.Container tight>
			<div style={{ display: "flex", justifyContent: "center" }}>
				<img src={data} alt="" className="w-100 h-100" />
			</div>
		</Styled.Container>
	);
};

export default Home;
