import React from "react";
import Loader from "react-loader-spinner";
import { Styled } from "../../utils";

const EmptyState = () => {
	return (
		<Styled.EmptyState>
			<Loader type="Oval" color="#000" />
		</Styled.EmptyState>
	);
};

export default EmptyState;
