import React from "react";
import { useSelector } from "../../hooks";
import { Styled, isEmpty } from "../../utils";
import { getArtistsList } from "../../utils/selectors";
import { ArtistList, EmptyState } from "../../components";

const Artists = () => {
	const { data } = useSelector(getArtistsList);
	return !data || isEmpty(data) ? (
		<EmptyState />
	) : (
		<Styled.Container tight>
			<Styled.Heading>Artists</Styled.Heading>
			{data?.map((group) => (
				<ArtistList medium={group.medium} list={group.artistList} />
			))}
		</Styled.Container>
	);
};

export default Artists;
