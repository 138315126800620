import React from "react";
import { StyledBurger } from "./burger.styled";
import { bool, func } from "prop-types";

const Burger = ({ open, setMenuOpen }) => {
	return (
		<StyledBurger open={open} onClick={() => setMenuOpen(!open)}>
			<div></div>
			<div></div>
			<div></div>
		</StyledBurger>
	);
};

Burger.propTypes = {
	open: bool.isRequired,
	setMenuOpen: func.isRequired,
};

export default Burger;
