import styled from "styled-components";

export const StyledBurger = styled.button.attrs({
	className:
		"flex flex-column justify-around pointer bn z-max pa0 bg-transparent",
})`
	width: 2rem;
	height: 2rem;
	&:focus {
		outline: none;
	}

	div {
		width: 2rem;
		height: 0.25rem;
		background: #000;
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;

		:first-child {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? "0" : "1")};
			transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
		}
	}
`;
