import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyles } from "./global";
import { theme } from "./theme";

import { Layout, PrivateRoute } from "./components";
import {
	About,
	Artists,
	Contact,
	Home,
	Photos,
	Press,
	Sponsors,
	VisitUs,
	Program,
} from "./pages";

import { Provider } from "./utils";

function App() {
	return (
		<ThemeProvider theme={theme}>
			<Provider>
				<GlobalStyles />
				<Layout>
					<div
						style={{
							minHeight: "calc(100vh - 106px - 8rem)",
							display: "flex",
							alignItems: "center",
						}}
					>
						<Switch>
							<Route exact path="/" component={Home} />
							<Route exact path="/about" component={About} />
							<Route exact path="/artists" component={Artists} />
							<Route exact path="/contact" component={Contact} />
							<PrivateRoute component={Photos} exact path="/photos" />

							<Route exact path="/press" component={Press} />
							<Route exact path="/sponsors" component={Sponsors} />
							<Route exact path="/visit-us" component={VisitUs} />
							<Route exact path="/calendar-and-events" component={Program} />
							<Redirect to="/" />
						</Switch>
					</div>
				</Layout>
			</Provider>
		</ThemeProvider>
	);
}

export default App;
