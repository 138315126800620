import React from "react";
import { StyledButton } from "./Button.styled";

const Button = ({ children, handleClick, disabled, ...rest }) => {
	return (
		<StyledButton onClick={handleClick} disabled={disabled} {...rest}>
			{children}
		</StyledButton>
	);
};

export default Button;
