import React from "react";
import ReactMarkdown from "react-markdown";
import { EmptyState } from "../../components";
import { useSelector } from "../../hooks";
import { Styled, isEmpty } from "../../utils";
import { getAbout } from "../../utils/selectors";

const About = () => {
	const { data } = useSelector(getAbout);
	return !data || isEmpty(data) ? (
		<EmptyState />
	) : (
		<Styled.Container tight>
			<Styled.Heading>About Collect It For the Culture</Styled.Heading>
			<ReactMarkdown children={data} />
		</Styled.Container>
	);
};

export default About;
