import React from "react";
import { useSelector } from "../../hooks";
import { Styled, isEmpty } from "../../utils";
import {
	getPressRelease,
	getSelectedPress,
	getMediaContact,
} from "../../utils/selectors";
import ReactMarkdown from "react-markdown";
import { EmptyState } from "../../components";

const Press = () => {
	const pressRelease = useSelector(getPressRelease);
	const selectedPress = useSelector(getSelectedPress);
	const mediaContact = useSelector(getMediaContact);
	return !pressRelease.data ||
		!selectedPress.data ||
		!mediaContact.data ||
		isEmpty(pressRelease?.data) ||
		isEmpty(selectedPress?.data) ||
		isEmpty(mediaContact?.data) ? (
		<EmptyState />
	) : (
		<Styled.Container jcc>
			<Styled.PressContainer>
				<Styled.Heading>Press</Styled.Heading>
				<ReactMarkdown children={pressRelease?.data} />
			</Styled.PressContainer>
			<Styled.PressContainer>
				<Styled.Heading bb>Selected Press</Styled.Heading>
				{selectedPress?.data?.map((link) => (
					<Styled.PressLink
						href={link.link}
						rel="noreferrer noopener"
						target="_blank"
					>
						{link.title}
					</Styled.PressLink>
				))}
			</Styled.PressContainer>
			<Styled.PressContainer>
				<Styled.Heading bb>Media Kit Contact</Styled.Heading>
				{mediaContact?.data?.map((contact) => (
					<Styled.FlexColumn>
						<Styled.ArtistMedium>{contact.contact}</Styled.ArtistMedium>
						<Styled.PressLink
							small
							mb4
							href={`mailto:${contact.email}`}
							rel="noreferrer noopener"
							target="_blank"
						>
							{contact.email}
						</Styled.PressLink>
					</Styled.FlexColumn>
				))}
			</Styled.PressContainer>
		</Styled.Container>
	);
};

export default Press;
