import React, { useState } from "react";
import { Link } from "react-router-dom";

import { EventbriteButton, Burger, Menu } from "../";
import { footerLinks, sponsors, Styled } from "../../utils";
import { getTickets } from "../../utils/selectors";
import { useSelector } from "../../hooks";

const Layout = ({ children }) => {
	const tickets = useSelector(getTickets);

	const [isMenuOpen, setMenuOpen] = useState(false);

	return (
		<>
			<Styled.MainContainer>
				<header
					className="flex justify-between items-center"
					style={{
						zIndex: 10,
					}}
				>
					<Link to="/" className="link black dim w-75 w-50-ns">
						<div style={{ fontWeight: 700, fontSize: "1.5rem" }}>
							Collect It For the Culture III
						</div>
						<div style={{ fontWeight: 400 }}>Houston</div>
						<div style={{ fontWeight: 200 }}>Jan 29 - Feb 28 2021</div>
					</Link>
					<div
						className={`${
							isMenuOpen ? "fixed" : "absolute"
						} flex justify-between right-4-ns right-2 items-center z-5`}
					>
						{tickets?.data?.eventId && (
							<div>
								<EventbriteButton
									classNames="mr4"
									isLoading={tickets?.loading && !tickets?.data.eventId}
									ebEventId={tickets?.data && tickets?.data?.eventId}
								>
									Get Tickets
								</EventbriteButton>
							</div>
						)}
						<Burger open={isMenuOpen} setMenuOpen={setMenuOpen} />
						<Menu open={isMenuOpen} setMenuOpen={setMenuOpen} />
					</div>
				</header>

				{children}
				<Styled.Footer style={{ backgroundColor: "#E5E5E5" }}>
					<Styled.FooterSection center>
						{sponsors?.map((sponsor) => (
							<Styled.FooterSponsorLink
								href={sponsor.link}
								rel="noreferrer noopener"
								target="_blank"
								key={sponsor.link}
							>
								<img src={sponsor.logo} alt="logo" className="w-100" />
							</Styled.FooterSponsorLink>
						))}
					</Styled.FooterSection>
					<Styled.FooterSection center>
						{footerLinks.map((link) => (
							<Link
								className="link dim f5"
								key={link.to}
								to={link.to}
								style={{ textTransform: "capitalize", color: "gray" }}
							>
								{link.title}
							</Link>
						))}
					</Styled.FooterSection>
				</Styled.Footer>
			</Styled.MainContainer>
		</>
	);
};

export default Layout;
