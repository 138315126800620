export const getHomeImage = (state) => state?.homeImage?.url;

export const getArtistsList = (state) => state?.ArtistsList;

export const getAbout = (state) => state?.about;

export const getPhotosOn = (state) => state?.photosOn;

export const getSelectedPress = (state) => state?.selectedPress;

export const getPressRelease = (state) => state?.pressRelease;

export const getMediaContact = (state) => state?.mediaContact;

export const getTickets = (state) => ({
	heading: state?.tickets?.pageHeading,
	eventId: state?.tickets?.eventId,
});

export const getCarousel = (state) =>
	state?.carousel.map((imgObj) => ({
		id: imgObj._id,
		url: imgObj.url,
	}));

export const getGallery = (state) =>
	state?.gallery.map((imgObj) => ({
		id: imgObj._id,
		url: imgObj.url,
		thumbnail: imgObj?.formats?.thumbnail?.url,
	}));

export const getSponsors = (state) => ({
	heading: state?.sponsors?.heading,
	sponsors: state?.sponsors?.sponsor.map((sponsor) => ({
		id: sponsor?._id,
		link: sponsor?.link,
		logo: sponsor?.logo?.formats?.small?.url || sponsor?.logo?.url,
	})),
});

export const getSocialLinks = (state) => state?.contact?.socialLink;

export const getContactInfo = (state) => ({
	emails: state?.contact?.contact,
	website: state?.contact?.website,
});
// socialLink.title === 'Facebook' && (
// 	)

// {socialLink.title === 'Instagram' && (
// 	{" "}
// 	)

export const getCalendar = (state) => {
	const calendar = state?.calendar;
	console.log(new Date("2011-04-12".replace(/-/g, "/") + " CST"));
	calendar?.sort(function compare(a, b) {
		let dateA = new Date(`${a.date.replace(/-/g, "/")} CST`);
		let dateB = new Date(`${b.date.replace(/-/g, "/")} CST`);

		return dateB - dateA;
	});

	return calendar;
};
