import React, { useEffect, useState, createContext } from "react";
import { useApiUrl } from "../hooks";

export const SiteContext = createContext();

export const Provider = ({ children }) => {
	const { API_URL } = useApiUrl(window.location);
	const [state, setState] = useState();
	const [loading, setLoading] = useState(true);

	const loadData = () => {
		try {
			fetch(`${API_URL}/collect-it-for-the-culture-site`, { method: "GET" })
				.then((res) => res.json())
				.then((loadedData) => setState(loadedData));
			setLoading(false);
		} catch (error) {
			console.error(error);
		}
	};

	useEffect(() => {
		loadData();
	}, []);

	return (
		<SiteContext.Provider value={[state, loading]}>
			{children}
		</SiteContext.Provider>
	);
};
