import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
	font-family: "Inter", sans-serif !important;
	height: 100vh;
	text-rendering: optimizeLegibility;
}

a:hover {
	text-decoration: none !important;
}


.right-3-ns {
	@media screen and (min-width: 30em) {
		right: 3rem;
	}
}
`;
