import React from "react";
import { Route, Redirect } from "react-router-dom";
import { getPhotosOn } from "../../utils/selectors";
import { useSelector } from "../../hooks";

const PrivateRoute = ({ component: Component, ...rest }) => {
	const { data } = useSelector(getPhotosOn);

	return (
		<Route
			render={(props) =>
				data ? <Component {...props} /> : <Redirect to="/" />
			}
			{...rest}
		/>
	);
};

export default PrivateRoute;
