import styled from "tachyons-components";
import styling from "styled-components";

export const MainContainer = styled("div")`
w-100 cf dark-gray bg-white ph3 ph3-m ph4-l pv3 pv3-m pv4-l center
`;

export const Container = styling.div.attrs({
	className: `mt5 flex flex-column`,
})`
margin: 0 auto;
max-width: ${({ tight }) => (tight ? "72rem" : "90vw")};
`;

export const ArtistsListContainer = styled("div")`mb5`;

export const PressContainer = styling("div")`
a {
	color: gray;
}
a:hover {
	color: black;
}
	h1 {
		margin-bottom: 1rem;
	}

	h2 {
		margin-bottom: 1rem;
	}

	h3 {
		font-size
		color: rgba(0, 0,0,0.4);
		letter-spacing: 0.1em;
		text-transform: uppercase;
		margin-bottom: 1rem;
		font-size: 1.24rem;
		line-height: 1.25;
		font-weight: 700; 
	}


	h4 {
		margin-bottom: 1rem;
	}

	max-width: 90vw;
	 
	@media screen and (min-width: 60em) {
	 width: 100%;
 	}

margin-bottom: 4rem;
	display: flex;
	flex-direction: column;

`;

export const Heading = styled("h1")`
f1-l f2 fw7 lh-title mb3-ns mb4
`;
export const Footer = styled("footer")`
mt5 pv4 ph3 ph5-ns tc  w-100 absolute  left-0 flex flex-column justify-center items-center
`;

export const FooterSponsorLink = styled("a")`
  link dim gray dib w-20-ns w-25 pointer
  
`;

export const FooterSection = styled("div")`
	${({ center }) =>
		center
			? "flex justify-around items-center w-75-ns w-100"
			: "flex justify-around"}
	mt4 w-50
`;

export const ArtistList = styled("div")`
w-100 flex flex-wrap
`;

export const ArtistName = styled("h3")`
f3 fw3 mb2 w-third-ns w-100
`;

export const ArtistMedium = styled("h2")`
f3 fw7 lh-title mb3 ttu tracked black-40
`;

export const PressLink = styled("a")`
link gray hover-black  fw3 mb2 dib w-100 w-33-ns  ${({ small }) =>
	small ? "f3" : "f2"}
`;

export const EmptyState = styled("div")`
	w-100 flex flex-column justify-center items-center

`;
export const SponsorsContainer = styled("div")`
	flex flex-column flex-row-l flex-wrap-l  items-center justify-center
`;

export const IconContainer = styled("div")`
dib h2 w2
`;
export const FlexColumn = styled("div")`
flex flex-column
`;
