import avoidCrowds from "../assets/icons/avoid-crowds.png";
import keepDistance from "../assets/icons/keep-distance.png";
import mask from "../assets/icons/mask.png";
import soap from "../assets/icons/soap.png";

export const navLinks = [
	{
		title: "About",
		to: "/about",
	},
	{
		title: "Artists",
		to: "/artists",
	},

	{
		title: "photos",
		to: "/photos",
	},
	{
		title: "press",
		to: "/press",
	},
	{
		title: "sponsors",
		to: "/sponsors",
	},
	{
		title: "visit us",
		to: "/visit-us",
	},
	{
		title: "programs",
		to: "/calendar-and-events",
	},
	{
		title: "volunteer",
		to:
			"https://docs.google.com/forms/d/e/1FAIpQLSfPBG7ZAakLjlMC5tMqmfHLv9THoDTikfN-rGY9D8nGeh_tvg/viewform",
	},
	{
		title: "contact",
		to: "/contact",
	},
	{
		title: "tickets",
		to: "/tickets",
	},
];

export const footerLinks = [
	{
		title: "visit us",
		to: "/visit-us",
	},
	{
		title: "press",
		to: "/press",
	},
	{
		title: "sponsors",
		to: "/sponsors",
	},
	{
		title: "contact",
		to: "/contact",
	},
];

export const sponsors = [
	{
		link: "https://www.blackbuddha.agency",
		logo:
			"https://bbca-dev-uploads.s3.amazonaws.com/small_bbuddha_logo_Type_1_4a63713abd.png",
	},
	{
		link: "http://www.robertleroyhodge.com/",
		logo:
			"https://bbca-dev-uploads.s3.amazonaws.com/small_hodge_copy_146e993715.png",
	},
	{
		link: "https://heist.agency/",
		logo:
			"https://images.squarespace-cdn.com/content/v1/5d1fb9d00f1dd60001e85402/1562367507466-OMR7OC4SUKPQY144F48G/ke17ZwdGBToddI8pDm48kBv5ChntMdapEi-JEUOJtMNZw-zPPgdn4jUwVcJE1ZvWQUxwkmyExglNqGp0IvTJZUJFbgE-7XRK3dMEBRBhUpxizjsay3q3cYtnxgqsaoTg1YG10WQJKJDYCQzMOy6XiOWuyy2wDkjcKS3BKpf6Fx4/heist+font+logo_med.png?format=750w",
	},
	{
		link: "https://www.rukaz.work",
		logo:
			"https://bbca-prod-uploads.s3.amazonaws.com/RUKAZLOGOS_B_0fde43fb74.png",
	},
];

export const visit = [
	{
		icon: keepDistance,
		heading: "Social Distancing",
		rules: [
			"The gallery space allows for at least six feet of space between occupants at all times",
			"Your groups can stay together, but we ask that you keep a minimum of six feet of distance between other groups",
		],
	},
	{
		icon: mask,
		heading: "Face Coverings",
		rules: [
			"Face coverings (covering mouth and nose) must be worn by all staff, volunteers, and visitors three years of age and older",
		],
	},
	{
		icon: avoidCrowds,
		heading: "Limited Capacity",
		rules: [
			"The gallery will reach maximum capacity at 100 visitors, staff, and volunteers",
		],
	},
	{
		icon: soap,
		heading: "Wellness",
		rules: [
			"Wash your hands and use hand sanitizer before arrival and throughout your visit",
			"If you are feeling ill or have been exposed to COVID-19 within the past 2 weeks, please visit at a later date.",
		],
	},
];
