import React from "react";
import { Styled } from "../../utils";

const ArtistList = ({ medium, list }) => {
	const artists = list.split(",");
	return (
		<Styled.ArtistsListContainer>
			<Styled.ArtistMedium>{medium}</Styled.ArtistMedium>
			<Styled.ArtistList>
				{artists.map((artist) => (
					<Styled.ArtistName>{artist}</Styled.ArtistName>
				))}
			</Styled.ArtistList>
		</Styled.ArtistsListContainer>
	);
};

export default ArtistList;
