import React from "react";
import { Styled } from "../../utils";
import { visit } from "../../utils";
const VisitUs = () => {
	return (
		<Styled.Container tight>
			<Styled.Heading>Visiting Us</Styled.Heading>
			<h2 className="f4 fw5 lh-copy bb b--black-20 pb4 mb5-ns mb4">
				At this time, we're open to the public starting Friday, January 29,
				2021. Keeping in mind your personal safety and the health of the
				community, we want to make sure you have a sanitary, contact-free
				experience. Learn what to expect on your visit, below.
			</h2>
			<div>
				{visit.map((block) => (
					<div
						key={block.heading}
						className="flex-column mb5-ns mb4 pb4 bb b--black-20"
					>
						<figure
							style={{ width: "90px", height: "90px" }}
							className="pa0 ma0"
						>
							<img src={block.icon} alt={block.heading} className="w-100" />
						</figure>
						<h3 className="f3">{block.heading}</h3>
						<ul>
							{block.rules.map((rule, i) => (
								<li key={i}>
									<p className="f4 fw5">{rule}</p>
								</li>
							))}
						</ul>
					</div>
				))}
			</div>
		</Styled.Container>
	);
};

export default VisitUs;
