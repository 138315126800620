import React from "react";

const Photos = () => {
	return (
		<div>
			<h1>Photos Page</h1>
		</div>
	);
};

export default Photos;
