import React from "react";
import { useSelector } from "../../hooks";
import { Styled, isEmpty } from "../../utils";
import { getCalendar } from "../../utils/selectors";
import { EmptyState } from "../../components";

const Program = () => {
	const { data } = useSelector(getCalendar);
	const options = { year: "numeric", month: "long", day: "numeric" };

	return !data || isEmpty(data) ? (
		<EmptyState />
	) : (
		<Styled.Container tight>
			<Styled.Heading>Programs</Styled.Heading>
			{data.map((item) => (
				<div className="bb b--black bw2 mb4" key={item._id}>
					<div>
						<h2 className="fw4 f2">{item.title}</h2>
						<h3 className="fw7 f3">
							{" "}
							{new Date(
								`${item.date.replace(/-/g, "/")} CST`
							).toLocaleDateString("en-US", options)}
						</h3>
					</div>
					<p className="fw3 f4">{item.description}</p>
					<h3 className="fw7 f3">
						<a
							className="link dim black-80 hover-black-40"
							href={item.link}
							target="_blank"
							rel="noopener noreferrer"
						>
							RSVP
						</a>
					</h3>
				</div>
			))}
		</Styled.Container>
	);
};

export default Program;
