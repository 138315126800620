import styled from "styled-components";

export const StyledMenu = styled.nav.attrs({
	className:
		"flex flex-column justify-around vh-100 tl pa6-l pa4 fixed top-0 right-0  z-999",
})`
	transition: transform 0.3s ease-in-out;
	transform: ${({ open }) => (open ? "translateX(0)" : "translateX(100%)")};
	background-color: #e5e5e5;

	width: 100%;
	@media screen and (min-width: 30em) {
		width: 25%;
	}
`;
