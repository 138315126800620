import React from "react";
import { Styled, isEmpty } from "../../utils";
import { getSponsors } from "../../utils/selectors";
import { useSelector } from "../../hooks";
import { EmptyState } from "../../components";

const Sponsors = () => {
	const { data } = useSelector(getSponsors);
	return !data || isEmpty(data) ? (
		<EmptyState />
	) : (
		<Styled.Container>
			<Styled.Heading>{data?.heading}</Styled.Heading>
			<Styled.SponsorsContainer>
				{data?.sponsors?.map((sponsor, idx) => (
					<a
						className="w-33-l w-75"
						href={sponsor.link}
						rel="noreferrer noopener"
						target="_blank"
						key={idx}
					>
						<img src={sponsor.logo} alt="logo" className="w-100 h-100" />{" "}
					</a>
				))}
			</Styled.SponsorsContainer>
		</Styled.Container>
	);
};

export default Sponsors;
